import Header from "../componentes/header";
import Corpo01 from "../componentes/corpor01";
import Corpo02 from "../componentes/corpo02";
import Sobre from "../componentes/Sobre";
import Perguntas from "../componentes/perguntas";

function Home() {
    return (
  <div>

  <Header/>
  <Corpo01/>
  <Corpo02/>
  <Sobre/>
  <Perguntas/>
  
  
  </div>
    );
  }
  
  export default Home;