import LogiN from "../componentes/login";
import "../style/style.css";

function Login() {
    return (
  <div>
     <LogiN />
  
  
   
  </div>
    );
  }
  
  export default Login;