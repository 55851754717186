import Cadastro from "../componentes/cadastrar/cadastrar";
import "../style/style.css";

function Cadastrar() {

    return (
  <div>

<Cadastro/>
  
  
  </div>
    );
  }
  
  export default Cadastrar;