import "../style/style.css";

function Saiba() {
    return (
  <div>
<h1>Saiba mais</h1>
  
  
  
  </div>
    );
  }
  
  export default Saiba;