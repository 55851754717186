import "../style/style.css";

function Notfound() {
    return (
  <div>
<h1>Página não encontrada</h1>
  
  
  
  </div>
    );
  }
  
  export default Notfound;